<infinite-scroll (scrolled)="!liste.isLocal && !liste.isContentHidden && onEndReached()">
	<div class="listview lv-bordered" [class.tile-wrap]="liste.isTile" [class.local]="liste.isLocal" [class.lv-lg]="!liste.isLocal" [class.has-selected-items]="liste.nbSelectedItems" [class.no-search]="liste.noSearch">
		<div class="lv-header-alt" [ngClass]="{ 'tile': liste.isTile }" *ngIf="!liste.isLoadingDisabled">
			<div class="title-container">
				<div>
					<button mat-icon-button *ngIf="!liste.isLocal && (routeData$ | async)?.isChild && filAriane$ | async as filAriane" (click)="goBack(filAriane)">
						<mat-icon>arrow_back</mat-icon>
					</button>
					<h2 class="lvh-label" *ngIf="!liste.isLocal">
						{{ liste.title }}
						<span *ngIf="liste.listeSelectors?.length" class="hidden-lg dropdown">
							<a [routerLink]="[]" data-toggle="dropdown" aria-expanded="true"><icon name="expand_more"></icon></a>
							<ul class="dropdown-menu dropdown-menu-right">
								<li *ngFor="let selector of liste.listeSelectors" (click)="selectSelector(selector)">
									<a [routerLink]="[]">
										<div class="pull-left"><icon name="check" *ngIf="selector.code == liste.selectedSelector"></icon></div>{{ selector.libelle }}<span *ngIf="selector.count">&#160;<span class="label label-info">{{ selector.count() }}</span></span>
									</a>
								</li>
							</ul>
						</span>
					</h2>
				</div>
				<span *ngIf="liste.listeSelectors?.length" class="visible-lg">
					<mat-button-toggle-group [value]="liste.selectedSelector">
						<mat-button-toggle *ngFor="let selector of liste.listeSelectors" [value]="selector.code" (click)="selectSelector(selector)">{{ selector.libelle }}<span *ngIf="selector.count">&#160;(<span>{{ selector.count() }}</span>)</span></mat-button-toggle>
					</mat-button-toggle-group>
				</span>
			</div>
			<ul class="lv-actions actions" *ngIf="(!liste.hasMainAction || liste.hasMainAction()) && liste.doMainAction">
				<li>
					<button mat-mini-fab color="primary" (click)="scrollToTop(); liste.doMainAction()">
						<mat-icon>add</mat-icon>
					</button>
				</li>
			</ul>
			<div class="search-container">
				<div *ngIf="!liste.nbSelectedItems && !liste.noSearch" list-view-search class="magicsearch-filter" [liste]="liste"></div>
				<list-view-actions *ngIf="liste.nbSelectedItems" [liste]="liste"></list-view-actions>
			</div>
		</div>
		<div class="lv-body" [ngClass]="liste.extraBodyClass" *ngIf="!liste.isContentHidden">
			<div [ngClass]="{ 'lv-item media': !liste.isTile,'tile tile-collapse': liste.isTile,'active': data.isDisplayed && !liste.noZoomEffect }" *ngFor="let data of getContentToDisplay()">
				<list-view-item [liste]="liste" [data]="data" [extraOptions]="liste.extraOptions" [component]="liste.component" (onDisplayChange)="onDisplayChange($event)" (onRemove)="onRemove($event)"></list-view-item>
			</div>
			<div class="text-center p-10" [ngClass]="{ 'tile': liste.isTile }" *ngIf="!liste.data || !liste.data.content">
				<h5><please-wait [message]="'liste.loading' | translate" size="hc-md"></please-wait></h5>
			</div>
			<div class="text-center p-10" [ngClass]="{ 'tile': liste.isTile }" *ngIf="liste.data?.content?.length == 0">
				<h5><span translate>liste.empty</span></h5>
			</div>
		</div>
	</div>
</infinite-scroll>