import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter,map,take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';
import { SESSION_FULFILLED,SESSION_LOGOUT_TO_ORIGINAL_USER,UPDATE_USER_ORIGINE } from 'src/app/reducers/session';

import { Result } from 'src/app/domain/common/http/result';
import { User } from 'src/app/domain/user/user';
import { environment } from 'src/environments/environment';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

/** Clés de stockage **/
const STORAGE_KEY: string = 'auth_token';
const STASH_KEY: string = 'original_auth_token';
const STASK_USER_KEY: string = 'original_user';

/**
 * Service de gestion de l'authentification
 */
@Injectable()
export class LoginService {
	/** Données **/
	private session: Session = null;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private store: Store<AppState>,private cookieService: CookieService,private menuService: MenuService
			,private translateService: TranslateService,private confirmService: ConfirmService,private toastrService: ToastrService,private bsModalService: BsModalService) {
		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
	}

	/**
	 * Accesseurs
	 */
	public getSession(): Session { return this.session; }

	/**
	 * Connexion
	 */
	public login(user: User,routeToRedirect: string,routeQueryParams: any,captchaToken: string): Observable<Session> {
		let params: URLSearchParams = new URLSearchParams();

		//Définition des données
		params.append('tenant[code]',user.tenant.code);
		params.append('login',user.login);
		params.append('password',user.password);
		params.append('idUser',user.idUser?.toString());

		//Connexion de l'utilisateur
		return this.http.post(`${environment.baseUrl}/_login`,params,{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				'X-AUTH-CAPTCHA': captchaToken || ''
			},
			observe: 'response'
		}).pipe(
			take(1),
			map(response => {
				let session: Session = new Session();
				let xAuthToken: string;
				let urlRedirection: string;

				//Lecture du jeton d'authentification
				xAuthToken = response.headers.get('x-auth-token');

				//Vérification du résultat
				if (xAuthToken) {
					//Définition de la session
					session.isLogged = true;
					session.xAuthToken = xAuthToken;
					session.route = routeToRedirect;
					session.queryParams = routeQueryParams;

					//Stockage de la session
					this.store.dispatch({
						type: SESSION_FULFILLED,
						payload: session
					});

					//Vérification de la présende d'un cookie de redirection
					if (this.cookieService.check('authRedirection')) {
						//Récupération de l'URL de redirection
						urlRedirection = this.cookieService.get('authRedirection');

						//Suppression du cookie de redirection
						this.cookieService.delete('authRedirection');

						//Redirection vers l'URL
						window.location.replace(urlRedirection);
					}
				} else {
					//Définition de la session
					session.isLogged = false;
					session.user = null;
					session.xAuthToken = null;
				}

				return session;
			})
		);
	}

	/**
	 * Déconnexion de l'utilisateur
	 */
	public logout(options?: { isCrossTabLogout?: boolean,isUserForbidden?: boolean,isFromCrossTab?: boolean }) {
		//Vérification que la déconnexion est initiée depuis l'onglet courant
		if (!options?.isFromCrossTab) {
			//Déconnexion de l'utilisateur sur le backend (asynchrone)
			this.http.post(`${environment.baseUrl}/controller/Login/logout`,null).subscribe({
				error: error => {
					//Vérification de l'erreur
					if (error instanceof HttpErrorResponse && error?.status != HttpStatusCode.Unauthorized)
						//Transmission de l'erreur
						throw error;
				}
			});
		}

		//Vérification de la déconnexion multi-onglets
		if (options?.isCrossTabLogout) {
			//Envoi d'un évènement de déconnexion via le localStorage
			localStorage.setItem('logout','true');

			//Retrait de l'évènement
			localStorage.removeItem('logout');
		}

		//Suppression du storage (session)
		sessionStorage.clear();

		//Suppression du storage (local)
		localStorage.clear();

		//Fermeture du menu
		this.menuService.hide();

		//Parcours des pop-ups encore ouvertes
		this.bsModalService['loaders']?.filter(l => l?.instance?.id).forEach(l => {
			//Fermeture de la pop-up
			this.bsModalService.hide(l.instance.id);
		});

		//Création d'une session vierge
		this.store.dispatch({
			type: SESSION_FULFILLED,
			payload: <Session>({
				isLogged: false,
				user: null,
				xAuthToken: null,
				userOrigine: null,
				isUserForbidden: options?.isUserForbidden
			})
		});
	}

	/**
	 * Connexion à la place du tenant
	 */
	public loginAsAdminTenant(tenant: any) {
		//Affichage du message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('login.connectAsTenant.confirmation'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Appel AJAX
				this.http.post<Result>(`${environment.baseUrl}/controller/Login/loginAsAdminTenant/${tenant.idTenant}`,null,{
					observe: 'response'
				}).pipe(take(1)).subscribe({
					next: response => {
						let xAuthToken: string;

						//Vérification de la réponse
						if (response?.body?.data?.adminTenant) {
							//Lecture du jeton d'authentification
							xAuthToken = response.headers.get('x-auth-token');

							//Vérification de l'absence d'utilisateur d'origine
							if (!this.session.userOrigine) {
								//Stockage de l'utilisateur d'origine
								this.store.dispatch({
									type: UPDATE_USER_ORIGINE,
									payload: this.session.user
								});
							}

							//Archivage des jetons d'origine
							localStorage.setItem(STASH_KEY,this.session.xAuthToken);
							localStorage.setItem(STASK_USER_KEY,JSON.stringify(this.session.user));

							//Stockage du jeton d'authentification
							localStorage.setItem(STORAGE_KEY,xAuthToken);

							this.store.dispatch({
								type: SESSION_FULFILLED,
								payload: {
									isLogged: true,
									xAuthToken: xAuthToken,
									user: response.body.data.adminTenant,
									userOrigine: this.session.user,
									route: 'dashboard'
								}
							});
						} else
							//Affichage d'un message d'erreur
							this.toastrService.error(this.translateService.instant('login.connectAsTenant.error'));
					}
				})
			}
		});
	}

	/**
	 * Déconnexion de l'utilisateur courant vers l'utilisateur d'origine
	 */
	public logoutToOriginalUser() {
		//Déconnexion de l'utilisateur vers l'utilisateur d'origine
		this.store.dispatch({
			type: SESSION_LOGOUT_TO_ORIGINAL_USER
		});
	}

	/**
	 * Demande d'envoi de mail de récupération d'un mot de passe oublié
	 */
	public retrievePassword(user: User,captchaToken: string): Observable<Result> {
		//Connexion de l'utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/retrievePassword`,user,{
			headers: {
				'X-AUTH-CAPTCHA': captchaToken
			}
		}).pipe(take(1));
	}

	/**
	 * Vérification de la validité d'un token
	 */
	public checkTokenValidity(token: string): Observable<{ isTokenNotUsed: boolean,isTokenNotExpired: boolean }> {
		//Vérification de la validité du token
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/checkTokenValidity/${token}`,null).pipe(
			take(1),
			map(result => ({
				isTokenNotUsed: result.data?.isNotUsed,
				isTokenNotExpired: result.data?.isNotExpired
			}))
		);
	}

	/**
	 * Mise à jour du mot de passe
	 */
	public updatePassword(user: User,token: string): Observable<Result> {
		//Vérification de la validité du token
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/setPassword/${token}`,user).pipe(take(1));
	}

	/**
	 * Vérification du mode d'authentification pour un tenant
	 */
	public checkModeAuthentification(codeTenant: string): Observable<'LOGIN_MDP' | 'FEDERATION' | 'BOTH'> {
		//Vérification des modes d'authentification disponibles pour le tenant
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/checkModeAuthentification/${codeTenant}`,null).pipe(
			take(1),
			map(result => result?.data?.modeAuthentification)
		);
	}

	/**
	 * Authentification par fédération d'identité
	 */
	public doFederatedLogin(user: User,authConfig: any) {
		let redirectUrl;

		//Définition des cookies
		this.cookieService.set('_oAuth2IdConfig',authConfig.idConfig);
		this.cookieService.set('_oAuth2Email',user.login);
		this.cookieService.set('_oAuth2RedirectUrl',redirectUrl = document.location.href.replace(/#.*$/,'controller/_oAuth2Login'));

		//Vérification du type
		if (authConfig.type == 'ADFS_OAUTH2') {
			//Ouverture de la mire de connexion
			window.open('https://login.microsoftonline.com/'+authConfig.applicationId+'/oauth2/authorize?client_id='+authConfig.clientId+'&response_type=code&response_mode=query&scope=openid%20email&redirect_uri='+redirectUrl+'&login_hint='+user.login,'_self');
		} else if (['ADFS_SAML2','SAML2'].includes(authConfig.type)) {
			//Ouverture de la mire de connexion
			window.location.replace(authConfig.saml2RequestUrl);
		} else if (authConfig.type == 'OAUTH2') {
			//Ouverture de la mire de connexion
			window.open(authConfig.oauth2AuthorizationUrl+(authConfig.oauth2AuthorizationUrl.indexOf('?') != -1 ? '&' : '?')+'client_id='+authConfig.clientId+'&response_type=code&response_mode=query&scope='+encodeURIComponent(authConfig.oauth2Scope || 'openid email')+'&redirect_uri='+encodeURIComponent(redirectUrl)+'&login_hint='+user.login+'&state=dummyState','_self');
		}
	}

	/**
	 * Vérification de la liste des configurations d'authentification disponibles
	 */
	public checkListeAuthConfigs(user: User,authConfig?: any,captchaToken?: string): Observable<{ isSeveralTenantFound: boolean,isCaptchaNeeded: boolean,listeAuthConfigs: Array<any> }> {
		//Vérification de la liste des configurations d'authentification disponibles
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/checkUserForOAuth2${authConfig?.idConfig ? '/' + authConfig?.idConfig : ''}`,user,{
			headers: captchaToken && {
				'X-AUTH-CAPTCHA': captchaToken
			}
		}).pipe(
			take(1),
			map(result => ({
				isSeveralTenantFound: result?.data?.isSeveralTenantFound || false,
				isCaptchaNeeded: result?.data?.isCaptchaNeeded || false,
				listeAuthConfigs: result?.data?.listeConfigs || []
			}))
		);
	}

	/**
	 * Vérification de la validité d'une règle pour le mot de passe
	 */
	public isRuleValid(password: string,rule: 'caracteres' | 'majuscules' | 'minuscules' | 'chiffres' | 'speciaux'): boolean {
		//Vérification du type de règle
		switch (rule) {
		case 'caracteres':
			//Taille
			return password?.length >= 12;
		case 'majuscules':
			//Majuscules
			return /[A-Z]/.test(password);
		case 'minuscules':
			//Minuscules
			return /[a-z]/.test(password);
		case 'chiffres':
			//Chiffres
			return /\d/.test(password);
		case 'speciaux':
			//Spéciaux
			return /[^A-Za-z0-9]/.test(password);
		}
	}

	/**
	 * Vérification de la complexité du mot de passe
	 */
	public isPasswordComplexe(password: string): boolean {
		//Validation du mot de passe
		return password?.length >= 12 && /\d/.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[^A-Za-z0-9]/.test(password);
	}

	/**
	 * Rafraichissement du token JWT
	 */
	public refreshToken(): Observable<Session> {
		//Rafraichissement du token
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/refresh`,null,{
			headers: {
				'TOKEN-TO-REFRESH': this.getSession().xAuthToken,
			},
			observe: 'response'
		}).pipe(
			map(response => {
				let session: Session = new Session();
				let xAuthToken: string;

				//Lecture du jeton d'authentification
				xAuthToken = response.headers.get('x-auth-token');

				//Vérification du résultat
				if (xAuthToken) {
					//Définition de la session
					session.isLogged = true;
					session.xAuthToken = xAuthToken;
					session.isRefresh = true;

					//Stockage de la session
					this.store.dispatch({
						type: SESSION_FULFILLED,
						payload: session
					});

					//AngularJS - Mise à jour du token
					localStorage.setItem('auth_token',xAuthToken);
				} else {
					//Définition de la session
					session.isLogged = false;
					session.user = null;
					session.xAuthToken = null;
				}

				return session;
			})
		);
	}

	/**
	 * Mise à jour du mot de passe
	 */
	public changePassword(user: User,passwordChange: { oldPassword: string,password: string,password2: string }): Observable<Result> {
		//Mise à jour du mot de passe
		return this.http.post<Result>(`${environment.baseUrl}/controller/Login/changePassword/${user.idUser}`,passwordChange);
	}

	/**
	 * Connexion en tant qu'utilisateur
	 */
	public loginAsUser(user: any) {
		//Affichage du message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('login.loginAsUser.confirmation'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Appel AJAX
				this.http.post<Result>(`${environment.baseUrl}/controller/Login/loginAsUser/${user.idUser}`,null,{
					observe: 'response'
				}).pipe(take(1)).subscribe({
					next: response => {
						let xAuthToken: string;

						//Vérification de la réponse
						if (response?.body?.data?.user) {
							//Lecture du jeton d'authentification
							xAuthToken = response.headers.get('x-auth-token');

							//Vérification de l'absence d'utilisateur d'origine
							if (!this.session.userOrigine) {
								//Stockage de l'utilisateur d'origine
								this.store.dispatch({
									type: UPDATE_USER_ORIGINE,
									payload: this.session.user
								});
							}

							//Archivage des jetons d'origine
							localStorage.setItem(STASH_KEY,this.session.xAuthToken);
							localStorage.setItem(STASK_USER_KEY,JSON.stringify(this.session.user));

							//Stockage du jeton d'authentification
							localStorage.setItem(STORAGE_KEY,xAuthToken);

							this.store.dispatch({
								type: SESSION_FULFILLED,
								payload: {
									isLogged: true,
									xAuthToken: xAuthToken,
									user: response.body.data.user,
									userOrigine: this.session.user,
									route: 'dashboard'
								}
							});
						} else
							//Affichage d'un message d'erreur
							this.toastrService.error(this.translateService.instant('login.loginAsUser.error'));
					}
				})
			}
		});
	}

	/**
	 * Connexion en tant que revendeur
	 */
	public loginAsAdminRevendeur(tenant: any) {
		//Affichage du message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('login.loginAsAdminRevendeur.confirmation'),{ actionColor: 'primary' }).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Appel AJAX
				this.http.post<Result>(`${environment.baseUrl}/controller/Login/loginAsAdminTenant/${tenant.idTenant}`,null,{
					observe: 'response'
				}).pipe(take(1)).subscribe({
					next: response => {
						let xAuthToken: string;

						//Vérification de la réponse
						if (response?.body?.data?.adminTenant) {
							//Lecture du jeton d'authentification
							xAuthToken = response.headers.get('x-auth-token');

							//Vérification de l'absence d'utilisateur d'origine
							if (!this.session.userOrigine) {
								//Stockage de l'utilisateur d'origine
								this.store.dispatch({
									type: UPDATE_USER_ORIGINE,
									payload: this.session.user
								});
							}

							//Archivage des jetons d'origine
							localStorage.setItem(STASH_KEY,this.session.xAuthToken);
							localStorage.setItem(STASK_USER_KEY,JSON.stringify(this.session.user));

							//Stockage du jeton d'authentification
							localStorage.setItem(STORAGE_KEY,xAuthToken);

							this.store.dispatch({
								type: SESSION_FULFILLED,
								payload: {
									isLogged: true,
									xAuthToken: xAuthToken,
									user: response.body.data.adminTenant,
									userOrigine: this.session.user,
									route: 'dashboard'
								}
							});
						} else
							//Affichage d'un message d'erreur
							this.toastrService.error(this.translateService.instant('login.loginAsAdminRevendeur.error'));
					}
				})
			}
		});
	}
}