<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">
	<span *ngIf="!data.anomalie">{{ (data.nature ? data.nature.libelle : data.libelleNature ? data.libelleNature : data.codeFournisseur.libelle).substring(0,1).toUpperCase() }}</span>
	<span *ngIf="data.anomalie"><icon name="warning"></icon></span>
</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addDetail?.(data,deleteDetail)">{{ (data.nature ? data.nature.libelle : data.libelleNature ? data.libelleNature : (data.codeFournisseur?.libelle || ('common.nonDefini' | translate))) + ' (' + factureService.getLibelleDetailFor(data) + ')' }}</a>
	</div>
	<ul class="lv-attrs">
		<li><span translate>facture.detail.date</span>&#160;:&#160;<span>{{ data.date | date:'shortDate' }}</span></li>
		<li><span translate>facture.detail.montant</span>&#160;:&#160;<span>{{ data.montant | currency:'.2-2':data.devise }}</span></li>
		<li *ngIf="data.montantTaxe"><span translate>facture.detail.taxe</span>&#160;:&#160;<span>{{ data.montantTaxe | currency:'.2-2':data.devise }}</span></li>
		<li *ngIf="data.montantHorsTaxe"><span translate>facture.detail.montantHorsTaxe</span>&#160;:&#160;<span>{{ data.montantHorsTaxe | currency:'.2-2':data.devise }}</span></li>
		<li><span translate>facture.detail.fournisseur</span>&#160;:&#160;<span>{{ data.facture.fournisseur.libelle }}</span></li>
		<li *ngIf="data.user && data.typeReconciliation != 'COLLABORATEUR'"><span translate>facture.detail.userAffecte</span>&#160;:&#160;<span>{{ data.user.prenom + ' ' + data.user.nom + ' (' + data.user.matricule + (data.user.societe ? ' - ' + data.user.societe.libelle : '') + ')' }}</span></li>
		<li *ngIf="data.typeReconciliation == 'COLLABORATEUR' && data.vehicule"><span translate>facture.detail.vehiculeImpute</span>&#160;:&#160;<span>{{ data.vehicule.reference + (data.vehicule.numeroInterne ? ' - ' + data.vehicule.numeroInterne : '') + ' (' + (data.vehicule.modele && data.vehicule.typeVehicule == 'VEHICULE_FONCTION_SERVICE' ? data.vehicule.modele.marque.libelle + ' ' + data.vehicule.modele.libelle : '') + (!data.vehicule.modele && data.vehicule.typeVehicule == 'VEHICULE_FONCTION_SERVICE' ? ('vehicule.modele.nonDefini' | translate) : '') + (data.vehicule.typeVehicule == 'ENGIN' && data.vehicule.marque ? data.vehicule.marque.libelle : '') + ')' }}</span></li>
		<li *ngIf="data.typeReconciliation == 'COLLABORATEUR' && !data.vehicule"><span translate>facture.detail.aucunVehiculeImpute</span></li>
		<li><span translate>facture.detail.statut.item</span>&#160;:&#160;<span [translate]="'facture.detail.statut.'+data.statut"></span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="!liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="(null | right:'creation') && !extraOptions.facture?.interfaceFournisseur && !['VALIDEE','COMPTABILISEE'].includes(extraOptions?.facture.statut)"><a [routerLink]="[]" (click)="deleteDetail()"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="(null | right:'creation') && !extraOptions.facture?.interfaceFournisseur && !['VALIDEE','COMPTABILISEE'].includes(extraOptions?.facture.statut)"><a [routerLink]="[]" (click)="extraOptions?.addDetail?.(data,deleteDetail,true)"><span translate>actions.dupliquer</span></a></li>
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification()"><span translate>actions.notifier</span></a></li>
			<li *ngIf="data.lien?.objet?.idVehicule && (TypeDroit.ADMIN_VEHICULE | right:'consultation') || data.lien?.objet?.idUser && (TypeDroit.ADMIN_UTILISATEUR | right:'consultation') || data.lien?.objet?.idEquipement && (TypeDroit.EQUIPEMENT | right:'consultation')"><a [routerLink]="[]" (click)="goToObject()"><span translate>actions.accederObjet</span></a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
		</ul>
	</div>
</div>