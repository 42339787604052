import { Component,Input,OnInit } from '@angular/core';

import { ListView,TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { environment } from 'src/environments/environment';
import { ImportSpecificationConnecteurListItemComponent } from './import-specification-connecteur-list-item.component';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { ImportSpecificationTemplateListItemComponent } from './import-specification-template-list-item.component';
import { ImportTemplateService } from 'src/app/share/components/import-template/import-template.service';

@Component({
	templateUrl: './import-specification.component.html'
})
export class ImportSpecificationComponent implements OnInit {
	/** Liste des connecteurs **/
	listeConnecteurs: ListView<any,ImportSpecificationConnecteurListItemComponent>;

	/** Liste des templates **/
	listeTemplates: ListView<any,ImportSpecificationTemplateListItemComponent>;

	/** Type de flux **/
	@Input() typeModule: TypeModule;

	/** Module sélectionné **/
	@Input() selectedModule: any = null;

	/** Fournisseur sélectionné **/
	@Input() selectedFournisseur: any;

	/** Format sélectionné **/
	@Input() selectedFormat: any = null;

	/** Connecteur sélectionné **/
	selectedConnecteur: any = null;

	/** Template sélectionné **/
	selectedTemplate: any = null;

	/** Sélection d'un élément **/
	onItemSelected: (payload?: { connecteur?: any,template?: any }) => void;

	/**
	 * Constructeur
	 */
	constructor(private importTemplateService: ImportTemplateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification de la sélection d'un fournisseur
		if (this.selectedFournisseur) {
			//Définition de la liste des connecteurs
			this.listeConnecteurs = new ListView<any,ImportSpecificationConnecteurListItemComponent>({
				uri: `${environment.baseUrl}/controller/Connecteur/filtreConnecteursForImport/${this.typeModule}/${this.selectedFournisseur.idFournisseur}`,
				component: ImportSpecificationConnecteurListItemComponent,
				isLocal: true,
				extraOptions: {
					onItemSelected: this.onItemSelected
				},
				listeFilters: [{
					clef: 'type.fournisseurInterface',
					type: TypeFilter.AUTOCOMPLETE,
					autocomplete: {
						type: 'fournisseurInterface'
					},
					isDefault: true
				}],
				listeStaticFilters: [{
					clef: 'type.fournisseurInterface.isActif',
					type: TypeFilter.BOOLEAN,
					valeur: true
				},{
					clef: 'isActif',
					type: TypeFilter.BOOLEAN,
					valeur: true
				}]
			});
		} else {
			//Définition de la liste des templates d'import
			this.listeTemplates = new ListView<any,ImportSpecificationTemplateListItemComponent>({
				uri: `${environment.baseUrl}/controller/ImportTemplate/filtreImportTemplates/${this.typeModule}`,
				component: ImportSpecificationTemplateListItemComponent,
				isLocal: true,
				extraOptions: {
					onItemSelected: this.onItemSelected,
					typeModule: this.typeModule,
					selectedFormat: this.selectedFormat
				},
				listeFilters: [{
					clef: 'libelle',
					isDefault: true
				}],
				listeStaticFilters: [{
					clef: 'path',
					valeur: this.selectedFormat.path,
					type: TypeFilter.STRING,
					typeComparaison: TypeComparaison.EQUAL
				}]
			});
		}
	}

	/**
	 * Ajout d'un template d'import
	 */
	public addImportTemplate() {
		//Affichage de la popup de création d'un template d'import
		this.importTemplateService.showImportTemplate(this.typeModule,this.selectedFormat).subscribe({
			next: importTemplate => {
				//Mise à jour de la liste
				this.listeTemplates.refresh();

				//Sélection du template d'import
				this.onItemSelected({ template: importTemplate });
			}
		});
	}
}