import { Component,OnInit,Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView } from 'src/app/domain/common/list-view';
import { ImportModuleListItemComponent } from './import-module-list-item.component';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ImportTemplateService } from 'src/app/share/components/import-template/import-template.service';


@Component({
	templateUrl: './import-module-list.component.html'
})
export class ImportModuleListComponent extends PageContentItem implements OnInit {
	/** Liste des modules **/
	liste: ListView<any,ImportModuleListItemComponent>;

	/** Sélection d'un module **/
	@Output() onModuleSelected: (module: any) => void;

	/**
	 * Constructeur
	 */
	constructor(private importTemplateService: ImportTemplateService,private translateService: TranslateService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des modules
		this.importTemplateService.retrieveListeModules().subscribe({
			next: listeModules => {
				//Définition de la liste
				this.liste = new ListView<any,ImportModuleListItemComponent>({
					uri: () => null,
					component: ImportModuleListItemComponent,
					isLocal: true,
					noSearch: true,
					isLoadingDisabled: true,
					extraOptions: {
						onModuleSelected: this.onModuleSelected
					}
				});

				//Récupération des modules avec leurs traductions triés par nom
				listeModules = (listeModules || []).map(module => Object.assign(module,{
					libelle: this.translateService.instant(`connecteur.import.typeModule.${module.typeModule}`)
				})).sort((a,b) => a.libelle.localeCompare(b.libelle));

				//Définition du contenu statique de la liste
				this.liste.data = {
					content: listeModules
				};
			}
		});
	}
}