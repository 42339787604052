import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest,Observable,of } from 'rxjs';
import { delay,first,map,tap } from 'rxjs/operators';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { ImportSelectionComponent } from './import/import-selection.component';
import { PleaseWaitService } from 'src/app/share/components/please-wait/please-wait.service';
import { PleaseWaitModalComponent } from 'src/app/share/components/please-wait/please-wait-modal.component';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { TypeComparaison,TypeFilter } from 'src/app/domain/common/list-view';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Injectable()
export class ConnecteurService {
	/** Cache des présences d'imports par type de module **/
	private mapImportsForTypeModule: { [typeModule: string]: { isAvailable: boolean,isAvailableConnecteurs: boolean,isAvailableImport: boolean } } = {};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private layoutService: LayoutService,private bsModalService: BsModalService,private pleaseWaitService: PleaseWaitService,private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Synchronisation des connecteurs
	 */
	public syncConnecteurs(): Observable<Result> {
		//Synchronisation des connecteurs
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/syncConnecteurs`,null);
	}

	/**
	 * Authentification sur le connecteur
	 */
	public login(connecteur: any): Observable<Result> {
		//Authentification sur le connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/login`,connecteur);
	}

	/**
	 * Exécution du connecteur
	 */
	public execute(connecteur: any): Observable<Result> {
		//Exécution du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/execute`,connecteur);
	}

	/**
	 * Vérification de la disponibilité d'un import
	 */
	public isImportAvailable(typeModule?: TypeModule): Observable<{ isAvailable: boolean,isAvailableConnecteurs: boolean,isAvailableImport: boolean }> {
		//Lecture du type de module (depuis la route si nécessaire)
		typeModule = typeModule || this.layoutService.getExtendedRouteData().typeModule;

		//Vérification de l'absence de cache
		if (this.mapImportsForTypeModule[typeModule] === undefined) {
			//Vérification de la disponibilité des imports (connecteurs ou templates d'import)
			return combineLatest([this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/countConnecteurs/${typeModule}`,{
				listeFilter: [{
					clef: 'type.typeConnecteur',
					valeur: 'INTERFACE',
					typeFilter: TypeFilter.STRING,
					typeComparaison: TypeComparaison.EQUAL
				}]
			}).pipe(first(),map(result => !!result?.data?.nbConnecteurs)),this.rightService.isRoot() || this.rightService.isRoot(true) ? this.http.post<Result>(`${environment.baseUrl}/controller/ImportTemplate/isModuleAvailable/${typeModule}`,null).pipe(first(),map(result => !!result?.data?.isAvailable)) : of(false)]).pipe(
				first(),
				tap(([isAvailableConnecteurs,isAvailableImport]) => this.mapImportsForTypeModule[typeModule] = { isAvailable: isAvailableConnecteurs || isAvailableImport,isAvailableConnecteurs,isAvailableImport }),
				map(([isAvailableConnecteurs,isAvailableImport]) => ({ isAvailable: isAvailableConnecteurs || isAvailableImport,isAvailableConnecteurs,isAvailableImport }))
			);
		} else
			//Retour du cache
			return of(this.mapImportsForTypeModule[typeModule]);
	}

	/**
	 * Affichage de la popup d'import
	 */
	public showImport(typeModule?: TypeModule) {
		//Lecture du type de module (depuis la route si nécessaire)
		typeModule = typeModule || typeModule === undefined && this.layoutService.getExtendedRouteData().typeModule || null;

		//Affichage de la popup
		this.bsModalService.show(ImportSelectionComponent,{
			initialState: {
				typeModule
			},
			class: 'modal-lg'
		});
	}

	/**
	 * Vérification de la disponibilité d'un connecteur
	 */
	public checkConnecteurAvailability(idConnecteur: number): Observable<boolean> {
		let bsModalRef: BsModalRef<PleaseWaitModalComponent>;

		//Affichage du message d'attente
		bsModalRef = this.pleaseWaitService.show({
			message: this.translateService.instant('connecteur.import.selection.verification')
		});

		//Vérification de la disponibilité du connecteur
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/checkConnecteurAvailability/${idConnecteur}`,null)
			.pipe(
				delay(200),
				map(result => result?.data?.connecteur?.actif),
				tap(() => bsModalRef.hide())
			);
	}

	/**
	 * Exécution de l'interface
	 */
	public executeInterface(connecteur: any): Observable<Result> {
		//Exécution de l'interface
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connecteur/executeInterface${connecteur.idConnecteur ? '/' + connecteur.idConnecteur : ''}`,connecteur);
	}

	/**
	 * Suppression du cache
	 */
	public evictCache() {
		//Suppression du cache
		this.mapImportsForTypeModule = {};
	}
}