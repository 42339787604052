import { Component,Input,OnInit,ViewChild } from '@angular/core';

import { AttachmentOptions } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result } from 'src/app/domain/common/http/result';
import { TypeModule } from 'src/app/domain/connecteur/type-module';
import { AttachmentComponent } from 'src/app/share/components/attachment/attachment.component';
import { FileWithProgress } from 'src/app/share/directive/file-uploader/file-uploader';
import { environment } from 'src/environments/environment';
import { ImportTemplateService } from 'src/app/share/components/import-template/import-template.service';

@Component({
	templateUrl: './import-attachments.component.html'
})
export class ImportAttachmentsComponent implements OnInit {
	/** Connecteur sélectionné **/
	@Input() selectedConnecteur: any;

	/** Type de module d'import sélectionné **/
	@Input() typeModule?: TypeModule;

	/** Module d'import sélectionné **/
	@Input() selectedModule?: any;

	/** Format sélectionné **/
	@Input() selectedFormat?: any;

	/** Template d'import sélectionné **/
	@Input() selectedTemplate?: any;

	/** Composant de gestion des attachments **/
	@ViewChild('attachment') attachmentComponent: AttachmentComponent;

	/** Options de gestion des attachments **/
	attachmentOptions: AttachmentOptions;

	/**
	 * Constructeur
	 */
	constructor(private importTemplateService: ImportTemplateService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition des options
		this.attachmentOptions = {
			getUrl: () => `${environment.baseUrl}/controller/Connecteur/saveAttachement${this.selectedConnecteur.idConnecteur ? '/' + this.selectedConnecteur.idConnecteur : ''}`,
			owningEntity: this.selectedConnecteur,
			typeAttachment: TypeAttachment.NON_DEFINI,
			inline: true,
			withoutAttachmentSaving: true,
			filters: '.csv|.txt|.dat|.xls|.xlsx|.xml',
			onCompleteItem: (file: File,result: Result,linkAttachment: any) => {
				//Vérification de la liste des liens
				this.selectedConnecteur.listeLinks = this.selectedConnecteur.listeLinks || [];

				//Vérification du fichier
				linkAttachment.attachment.isVerified = result?.data?.isVerified;

				//Définition de l'identifiant du fichier externe
				linkAttachment.attachment.fileUid = result?.data?.fileUid;

				//Définition de l'identifiant de la prédiction de format
				linkAttachment.attachment.predictionId = result?.data?.predictionId;

				//Définition du type de retour chariot
				linkAttachment.attachment.typeRetourChariot = result?.data?.typeRetourChariot;

				//Ajout du lien
				this.selectedConnecteur.listeLinks.push(linkAttachment);
			},
			isFromLink: true
		};
	}

	/**
	 * Récupération de la queue des fichiers à uploader
	 */
	getUploadQueue(): Array<FileWithProgress> {
		//Retour de la queue
		return this.attachmentComponent?.fileUploader?.queue;
	}

	/**
	 * Upload de l'ensemble des fichiers
	 */
	uploadAll() {
		//Upload de l'ensemble des fichiers
		this.attachmentComponent.uploadAll();
	}

	/**
	 * Génération d'un fichier d'exemple
	 */
	generateSample() {
		//Génération du fichier d'exemple
		this.importTemplateService.generateSampleFile(this.selectedTemplate).subscribe();
	}
}