import { Injectable } from '@angular/core';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter,map,tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { UPDATE_USER } from 'src/app/reducers/session';
import { Action } from 'src/app/domain/action';
import { DashboardService } from './dashboard.service';
import { CalendarService } from 'src/app/share/components/calendar/calendar.service';
import { PREVIEW_CHART } from 'src/app/reducers/dashboard';
import { ChartService } from 'src/app/components/chart/chart.service';

@Injectable()
export class DashboardEffects {
	/**
	 * Constructeur
	 */
	constructor(private actions$: Actions,private dashboardService: DashboardService,private calendarService: CalendarService,private chartService: ChartService) {

	}

	/**
	 * Suppression du cache
	 */
	resetCache$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(UPDATE_USER),
		map(action => {
			//Suppression du cache
			this.dashboardService.evictCache();
			this.calendarService.evictCache();

			//Retour de l'action
			return action;
		})
	),{ dispatch: false });

	/**
	 * Prévisualisation d'un graphique
	 */
	previewChart$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(PREVIEW_CHART),
		filter((action: Action) => !!action.payload?.chart),
		tap((action: Action) => {
			//Prévisualisation du graphique
			this.chartService.previewChart(cloneDeep(action.payload.chart),false);
		})
	),{ dispatch: false });
}