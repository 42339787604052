/**
 * Enumération des types de module
 */
export enum TypeModule {
	/** Enumération **/
	FACTURE = 'FACTURE',
	COLLABORATEUR = 'COLLABORATEUR',
	ANALYTIQUE = 'ANALYTIQUE',
	VEHICULE_REFERENTIEL = 'VEHICULE_REFERENTIEL',
	VEHICULE = 'VEHICULE',
	CONDUCTEUR	= 'CONDUCTEUR',
	EQUIPEMENT = 'EQUIPEMENT',
	FOURNISSEUR = 'FOURNISSEUR',
	COMPTABILITE = 'COMPTABILITE',
	DEMANDE_IDENTIFICATION_REFERENTIEL = 'DEMANDE_IDENTIFICATION_REFERENTIEL'
}