import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Filter,ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
import { ImportListItemComponent } from './import-list-item.component';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { ImportService } from './import.service';
import { Result } from 'src/app/domain/common/http/result';
import { MessagingOptions } from 'src/app/domain/messaging/messaging';
import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { ConnecteurService } from 'src/app/share/components/connecteur/connecteur.service';

@Component({
	templateUrl: './import-list.component.html'
})
export class ImportListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ImportListItemComponent>;

	/** Présence d'un dashboard **/
	hasDashboard: boolean = false;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,public dashboardService: DashboardService,private rightService: RightService,private importService: ImportService,public connecteurService: ConnecteurService) {

	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let isRevendeur: boolean;
		let isRoot: boolean;

		//Récupération du type de tenant
		isRevendeur = this.rightService.isRevendeur();
		isRoot = this.rightService.isRoot() || this.rightService.isRoot(true);

		//Définition de la liste
		this.liste = new ListView<any,ImportListItemComponent>({
			uri: '/controller/Connecteur/filtreImports',
			title: this.translateService.instant('connecteur.import.liste.title'),
			component: ImportListItemComponent,
			isTile: true,
			listeFilters: [isRevendeur && {
				clef: 'tenant',
				type: TypeFilter.AUTOCOMPLETE,
				title: this.translateService.instant('search.client'),
				autocomplete: {
					type: 'tenant'
				},
				isDefault: true
			},{
				clef: '*connecteur.type',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'connecteurType',
					filter: {
						isShowAll: true
					}
				},
				isDefault: true
			},{
				clef: 'correlationId',
				title: this.translateService.instant('connecteur.import.correlationId'),
				isDefault: true
			},isRoot && {
				clef: 'typeExecution',
				type: TypeFilter.STRING,
				title: this.translateService.instant('connecteur.typeExecution.item'),
				listeValues: ['NON_DEFINI','INTEGRATION','MIDDLEWARE'].map(code => ({
					code,
					libelle: this.translateService.instant(`connecteur.typeExecution.${code}`)
				}))
			},{
				clef: '-dateExecution',
				type: TypeFilter.DATE,
				date: { format: 'datetime' }
			} as Filter,{
				clef: 'isSuccess',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'apiSession.statut',
				type: TypeFilter.STRING,
				title: this.translateService.instant('connecteur.import.statut.item'),
				listeValues: ['FAILED','PENDING','FINISHED'].map(code => ({
					code,
					libelle: this.translateService.instant(`connecteur.import.statut.${code}`)
				}))
			},{
				clef: 'apiSession.nbCreations',
				title: this.translateService.instant('connecteur.import.resultat.nbCreations'),
				type: TypeFilter.DECIMAL
			},{
				clef: 'apiSession.nbUpdates',
				title: this.translateService.instant('connecteur.import.resultat.nbUpdates'),
				type: TypeFilter.DECIMAL
			},{
				clef: 'apiSession.nbIgnores',
				title: this.translateService.instant('connecteur.import.resultat.nbIgnores'),
				type: TypeFilter.DECIMAL
			},{
				clef: 'apiSession.nbInfos',
				title: this.translateService.instant('connecteur.import.resultat.infos'),
				type: TypeFilter.DECIMAL
			},{
				clef: 'apiSession.nbWarnings',
				title: this.translateService.instant('connecteur.import.resultat.warnings'),
				type: TypeFilter.DECIMAL
			},{
				clef: 'apiSession.nbErrors',
				title: this.translateService.instant('connecteur.import.resultat.errors'),
				type: TypeFilter.DECIMAL
			}].filter(i => !!i),
			defaultOrder: '-dateExecution',
			getKeyFieldName: () => 'idExecution',
			listeActions: [(this.rightService.hasRight(TypeDroit.ADMIN_IMPORT,'creation') || this.rightService.hasRight(TypeDroit.ADMIN_IMPORT,'suppression')) && {
				icon: 'refresh',
				messagingOptions: {
					entryPoint: 'controller/Connecteur/retryImport/FULL/0'
				},
				onPress: (_: ActionMasse,messagingOptions: MessagingOptions) => this.retryListeImports(messagingOptions)
			}].filter(i => !!i),
			hasMainAction: () => this.rightService.hasRight(TypeDroit.ADMIN_IMPORT,'consultation') && isRoot,
			doMainAction: () => {
				//Affichage de la popup d'import
				this.connecteurService.showImport();
			}
		});

		//Vérification de la présence d'un dashboard
		this.dashboardService.hasDashboard().subscribe({
			next: (hasDashboard: boolean) => {
				//Définition de la présence d'un dashboard
				this.hasDashboard = hasDashboard;
			}
		});
	}

	/**
	 * Relance des imports
	 */
	retryListeImports(messagingOptions: MessagingOptions) {
		let actionMasse: ActionMasse;
		let searchSpec: any;
		let nbImportsOK: number;
		let nbImportsKO: number;

		//Récupération de l'action de masse
		actionMasse = messagingOptions.params as ActionMasse;

		//Récupération de la recherche
		searchSpec = actionMasse.searchSpec;

		//Vérification du type d'action
		if (messagingOptions.params.typeActionMasse == 'FULL') {
			//Dénombrement des imports
			this.importService.countImports(true,searchSpec).subscribe({
				next: (result: Result) => {
					//Récupération des compteurs
					nbImportsOK = result.data.nbImports;
					nbImportsKO = this.liste.data.totalElements - nbImportsOK;

					//Relance de l'import pour la sélection
					this.importService.retryImportForSelection(nbImportsOK,nbImportsKO,messagingOptions).subscribe({
						complete: () => this.liste.refresh()
					});
				}
			});
		} else {
			//Recherche du nombre d'imports OK parmi les imports sélectionnés
			nbImportsOK = this.liste.data.content.filter(i => i.success && messagingOptions.params.listeIdObjects.includes(i.idExecution)).length;

			//Recherche du nombre d'imports KO
			nbImportsKO = messagingOptions.params.listeIdObjects.length - nbImportsOK;

			//Relance de l'import pour la sélection
			this.importService.retryImportForSelection(nbImportsOK,nbImportsKO,messagingOptions).subscribe({
				complete: () => this.liste.refresh()
			});
		}
	}
}