<div class="alert alert-info" *ngIf="selectedTemplate">
	<icon name="dataset"></icon>
	<div>
		<strong translate>connecteur.import.template.exemple.title</strong>
		<div translate>connecteur.import.template.exemple.description</div>
	</div>
	<button mat-raised-button color="primary" (click)="generateSample()">
		<span translate>actions.generer</span>
	</button>
</div>
<div>
	<attachment #attachment="attachment" [options]="attachmentOptions"></attachment>
</div>